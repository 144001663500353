import React from "react";
import { chunk, orderBy } from "lodash";

import { Layout, PropertyListings, Button, Link, Carousel, PropertyItem } from "components";
import { useRecentThreeProperties } from "hooks";

const sortProperties = (sortType, propertiesToSort) => {
  const sortedProperties = [];
  switch (sortType) {
    case "newest-first":
      sortedProperties.push(...orderBy(propertiesToSort, [(property) => new Date(property.date)], ["desc"]));
      break;
    case "oldest-first":
      sortedProperties.push(...orderBy(propertiesToSort, [(property) => new Date(property.date)], ["asc"]));
      break;
    case "price-asc":
      sortedProperties.push(...orderBy(propertiesToSort, ["searchPrice"], ["asc"]));
      break;
    case "price-desc":
      sortedProperties.push(...orderBy(propertiesToSort, ["searchPrice"], ["desc"]));
      break;
    case "suburb-asc":
      sortedProperties.push(...orderBy(propertiesToSort, ["suburb"], ["asc"]));
      break;
    case "suburb-desc":
      sortedProperties.push(...orderBy(propertiesToSort, ["suburb"], ["desc"]));
      break;
    default:
      return propertiesToSort;
  }

  return sortedProperties;
};

const PropertySearchPage = ({ params, location }) => {
  if (!location?.state) return null;

  const { properties, searchParams, selectedLifestyleFilters, sortValue = "newest-first" } = location.state;
  const { propertyType, pageNumber } = params;

  if (properties?.length === 0) {
    const suggestedProperties = useRecentThreeProperties();
    const suggestedPropertiesSettings = {
      id: "similar-property-carousel",
      slidesPerView: 1,
      spaceBetween: 16,
      grabCursor: false,
      pagination: {
        clickable: true,
      },
      navigation: false,
      breakpoints: {
        740: {
          navgiation: false,
          slidesPerView: 2,
        },
        1080: {
          navgiation: false,
          slidesPerView: 3,
        },
      },
    };
    return (
      <Layout
        pageTitle={"Property Search | Davey Real Estate"}
        className="dark"
        contentClassname="layout"
        layoutType="property-list"
        searchParams={searchParams}
        primaryPropertyCategory={propertyType}
      >
        <div className="section">
          <p className="typography--h2">Sorry, no properties were returned from your search.</p>
          <h3>Our most recent listings</h3>
          <Carousel swiperSettings={suggestedPropertiesSettings}>
            {Object.keys(suggestedProperties).length > 0 &&
              suggestedProperties?.map((similarProperty) => {
                return <PropertyItem key={similarProperty.id} property={similarProperty} minimal />;
              })}
          </Carousel>
        </div>
      </Layout>
    );
  }

  const sortedProperties = sortProperties(sortValue, properties);

  const humanPageNumber = pageNumber.length === 0 ? "1" : pageNumber;

  const paginatedProperties = chunk(sortedProperties, 10);
  const propertiesForPage = paginatedProperties[humanPageNumber - 1];

  const startIndex = parseInt(humanPageNumber) === 1 ? 1 : parseInt(humanPageNumber) * 10;
  const endIndex = startIndex + propertiesForPage.length - 1;

  return (
    <Layout
      pageTitle={"Property Search | Davey Real Estate"}
      className="dark"
      contentClassname="layout"
      layoutType="property-list"
      searchParams={searchParams}
      primaryPropertyCategory={propertyType}
      lifestyleFilters={selectedLifestyleFilters}
      sortValue={sortValue}
    >
      <PropertyListings
        location={location}
        data={{
          enableBreadcrumbs: true,
          listTitle: `Property Search - ${propertyType}`,
          listSubTitle: "Search results",
          properties: propertiesForPage,
          propertiesToSort: properties,
          numberOfProperties: properties.length,
          propertiesPerPage: 10,
          currentPageNumber: parseFloat(humanPageNumber),
          numberOfPages: paginatedProperties.length,
          sortValue,
          startIndex,
          endIndex,
        }}
      />
    </Layout>
  );
};

export default PropertySearchPage;
